<script setup>
import Logo from "@/components/appCommon/Logo.vue"
import SidebarToggle from "@/components/appCommon/SidebarToggle.vue";
import { onMounted, watch } from "vue";
import { Collapse } from "bootstrap";
import { useRoute } from "vue-router"

const route = useRoute();

watch(route, () => {
    document.body.classList.remove("sidebar-reverse");
})

const items = [
    {
        key: "dashboard",
        route: { name: "Dashboard" },
        title: "Durum",
        icon: "bi-speedometer2",
    },
    {
        key: "contacts",
        title: "Cari Hesaplar",
        icon: "bi-building",
        children: [
            { route: { name: "Customers" }, title: "Müşteriler" },
            { route: { name: "Suppliers" }, title: "Tedarikçiler" },
        ],
    },
    {
        key: "invoices",
        title: "Faturalar",
        icon: "bi-file-earmark-richtext",
        children: [
            { route: { name: "SaleInvoices" }, title: "Satış Faturaları" },
            { route: { name: "PurchaseInvoices" }, title: "Alış Faturaları" },
            { route: { name: "InboxEInvoices" }, title: "Gelen E-Fatura" },
        ],
    },
    {
        key: "expenses",
        route: { name: "Expenses" },
        title: "Masraflar",
        icon: "bi-wallet",
    },
    {
        key: "products",
        route: { name: "Products" },
        title: "Ürün ve Hizmetler",
        icon: "bi-boxes",
    },
    {
        key: "accounts",
        route: { name: "Accounts" },
        title: "Kasa ve Bankalar",
        icon: "bi-bank",
    },
    {
        key: "reports",
        title: "Raporlar",
        icon: "bi-graph-up-arrow",
        children: [
            { route: { name: "ProfitAndLossSummary" }, title: "Kâr - Zarar" },
            { route: { name: "TaxSummary" }, title: "Vergi Özeti" },
            { route: { name: "IncomingSummary" }, title: "Gelir Özeti" },
            { route: { name: "OutgoingSummary" }, title: "Gider Özeti" },
        ],
    },
];

onMounted(() => {
    const collapseElements = [].slice.call(
        document.querySelectorAll(".collapse")
    );
    const collapseList = collapseElements.map((element) => {
        return new Collapse(element, {
            toggle: false,
        });
    });

    /* Aktif olan submenu varsa collapse i aç */
    collapseList.forEach((element) => {
        if (element._element.querySelector(".active")) {
            element.show();
        }
    });
});
</script>

<template>
    <div class="sidebar">
        <div class="sidebar-header">
            <div class="text-center">
                <Logo />
            </div>
            <div class="position-absolute end-0 pe-3 d-lg-none">
                <SidebarToggle :toggleClose="true" />
            </div>
            
        </div>

        <div class="sidebar-body">
            <ul id="sidebar-menu" class="sidebar-menu">
                <template v-for="item in items" :key="item.key">
                    <li v-if="!item.children" class="menu-item">
                        <router-link
                            :to="item.route"
                            custom
                            v-slot="{ href, navigate, isActive }"
                        >
                            <a
                                class="menu-link"
                                :class="{ active: isActive }"
                                :href="href"
                                @click="navigate"
                            >
                                <i class="menu-icon" :class="item.icon"></i>
                                <span class="menu-title">
                                    {{ item.title }}
                                </span>
                            </a>
                        </router-link>
                    </li>
                    <li v-else class="menu-item">
                        <a
                            class="menu-link"
                            data-bs-toggle="collapse"
                            :href="`#${item.key}`"
                            aria-expanded="false"
                            :aria-controls="item.key"
                        >
                            <i class="menu-icon" :class="item.icon"></i>
                            <span class="menu-title"> {{ item.title }} </span>
                        </a>
                        <div
                            class="collapse"
                            :id="item.key"
                            data-bs-parent="#sidebar-menu"
                        >
                            <ul class="sub-menu">
                                <li
                                    v-for="(c, index) in item.children"
                                    :key="index"
                                    class="menu-item"
                                >
                                    <router-link
                                        :to="c.route"
                                        custom
                                        v-slot="{ href, navigate, isActive }"
                                    >
                                        <a
                                            class="menu-link"
                                            :class="{ active: isActive }"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            {{ c.title }}
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </template>
            </ul>
        </div>

        <div class="sidebar-footer">
            <ul class="sidebar-menu">
                <li class="menu-item">
                    <a
                        class="menu-link"
                        data-bs-toggle="collapse"
                        href="#settings"
                        aria-expanded="false"
                        aria-controls="settings"
                    >
                        <i class="bi bi-gear menu-icon"></i>
                        <span class="menu-title"> Ayarlar </span>
                    </a>
                    <div
                        class="collapse"
                        id="settings"
                        data-bs-parent="#sidebar-menu"
                    >
                        <ul class="sub-menu">
                            <li class="menu-item">
                                <router-link
                                    :to="{ name: 'CompanySettings' }"
                                    custom
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        class="menu-link"
                                        :class="{ active: isActive }"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        Firma Ayarları
                                    </a>
                                </router-link>
                            </li>
                            <li class="menu-item">
                                <router-link
                                    :to="{ name: 'EInvoiceSettings' }"
                                    custom
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        class="menu-link"
                                        :class="{ active: isActive }"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        E-Fatura Ayarları
                                    </a>
                                </router-link>
                            </li>
                            <li class="menu-item">
                                <router-link
                                    :to="{ name: 'CompanySettings' }"
                                    custom
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        class="menu-link"
                                        :class="{ active: isActive }"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        Kullanıcılar
                                    </a>
                                </router-link>
                            </li>
                            <li class="menu-item">
                                <router-link
                                    :to="{ name: 'Categories' }"
                                    custom
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        class="menu-link"
                                        :class="{ active: isActive }"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        Kategoriler
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="menu-item">
                    <a
                        href="#"
                        class="menu-link"
                        @click.prevent="$store.dispatch('auth/logout')"
                    >
                        <i class="bi bi-box-arrow-right menu-icon"></i>
                        <span class="menu-title"> Çıkış Yap </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: $sidebar-width;
    height: 100vh;
    color: var(--body-color-2);
    background-color: var(--main-bg);
    overflow-y: auto;
    z-index: 1046;
    -webkit-overflow-scrolling: touch;
    will-change: transform;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.02);
    transition: 0.4s ease-in-out;

    .sidebar-reverse & {
        margin-left: -100%;
    }

    .sidebar-header {
        padding: 1rem;
    }

    .sidebar-body {
        padding: 0.5rem 0;
    }

    .sidebar-footer {
        margin-top: auto;
        padding-bottom: 1rem;
        // padding: 1rem;
    }

    .sidebar-menu {
        list-style: none;
        margin: 0;
        padding: 0;

        .menu-item {
            .menu-link {
                color: var(--body-color-2);
                display: flex;
                align-items: center;
                font-size: 0.925rem;
                padding: 0.563rem 1rem 0.563rem 1.5rem;

                &.active {
                    color: var(--bs-primary) !important;
                }

                &[data-bs-toggle="collapse"] {
                    &::after {
                        content: "\F282";
                        display: inline-block;
                        font-family: "bootstrap-icons";
                        transition: transform 0.5s;
                    }

                    &[aria-expanded="true"] {
                        &::after {
                            transform: rotate(-180deg);
                        }
                    }
                }

                .menu-icon {
                    font-size: 1.4rem;
                    margin-right: 1rem;
                }
                .menu-title {
                    flex-grow: 1;
                    font-size: 1.1rem;
                    font-weight: 400;
                }
            }
        }
    }

    .sub-menu {
        margin-left: 2.5rem;

        .menu-item {
            .menu-link {
                font-size: 1rem;
                padding: 0.5rem !important;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .sidebar {
        margin-left: -$sidebar-width;
        box-shadow: none;

        .sidebar-reverse & {
            margin-left: 0;
            top: 0;
            width: 100%;
        }

        .sidebar-header {
            display: flex;

            a {
                flex-grow: 1;
            }
        }

        .sidebar-menu {
            .menu-item {
                .menu-link {
                    font-size: 1.2rem;
                    padding: 1rem 2rem;
                }
            }
        }
    }
}
</style>
