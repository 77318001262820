import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import AuthBaseView from "../views/auth/AuthBaseView.vue";
import LoginView from "../views/auth/LoginView.vue";
import AppBaseView from "../views/app/AppBaseView.vue";

const routes = [
    {
        path: "/",
        component: AuthBaseView,
        beforeEnter: (to, from, next) => {
            const isAuthenticated = store.getters["auth/isAuth"];
            if (isAuthenticated) next({ name: "Dashboard" });
            else next();
        },
        children: [
            {
                path: "/",
                name: "Login",
                component: LoginView,
                meta: { title: "Giriş Yap" },
            },
        ],
    },
    {
        path: "/app",
        component: AppBaseView,
        beforeEnter: async (to, from, next) => {
            const isAuthenticated = store.getters["auth/isAuth"];
            // if (!isAuthenticated) next({ name: "Login" });
            // else next();

            if (!isAuthenticated) {
                next({ name: "Login" });
            } else {
                await store.dispatch("auth/initAuth");
                next();
            }
        },
        children: [
            {
                path: "durum",
                name: "Dashboard",
                component: () =>
                    import(
                        /* webpackChunkName: "Dashboard" */ "../views/app/dashboard/IndexView.vue"
                    ),
                meta: { title: "Durum", page_title: "Durum" },
            },
            {
                path: "cari-hesaplar",
                children: [
                    {
                        path: "musteriler",
                        children: [
                            {
                                path: "",
                                name: "Customers",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "Customers" */ "../views/app/contacts/ListView.vue"
                                    ),
                                meta: {
                                    title: "Müşteriler",
                                    page_title: "Müşteriler",
                                },
                            },
                            {
                                path: "yeni",
                                name: "CreateCustomer",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "CreateCustomer" */ "../views/app/contacts/CreateView.vue"
                                    ),
                                meta: {
                                    title: "Yeni Müşteri",
                                    page_title: "Yeni Müşteri",
                                },
                            },
                            {
                                path: ":id",
                                name: "Customer",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "Customer" */ "../views/app/contacts/ShowView.vue"
                                    ),
                                meta: { title: "Müşteri" },
                            },
                            {
                                path: ":id/guncelle",
                                name: "UpdateCustomer",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "UpdateCustomer" */ "../views/app/contacts/CreateView.vue"
                                    ),
                                meta: {
                                    title: "Müşteri Güncelle",
                                    page_title: "Güncelle",
                                },
                            },
                        ],
                    },
                    {
                        path: "tedarikciler",
                        children: [
                            {
                                path: "",
                                name: "Suppliers",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "Suppliers" */ "../views/app/contacts/ListView.vue"
                                    ),
                                meta: {
                                    title: "Tedarikçiler",
                                    page_title: "Tedarikçiler",
                                },
                            },
                            {
                                path: "yeni",
                                name: "CreateSupplier",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "CreateSupplier" */ "../views/app/contacts/CreateView.vue"
                                    ),
                                meta: {
                                    title: "Yeni Tedarikçi",
                                    page_title: "Yeni Tedarikçi",
                                },
                            },
                            {
                                path: ":id",
                                name: "Supplier",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "Supplier" */ "../views/app/contacts/ShowView.vue"
                                    ),
                                meta: { title: "Tedarikçi" },
                            },
                            {
                                path: ":id/guncelle",
                                name: "UpdateSupplier",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "UpdateSupplier" */ "../views/app/contacts/CreateView.vue"
                                    ),
                                meta: {
                                    title: "Tedarikçi Güncelle",
                                    page_title: "Güncelle",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "faturalar",
                children: [
                    {
                        path: "satis-faturalari",
                        children: [
                            {
                                path: "",
                                name: "SaleInvoices",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "SaleInvoices" */ "../views/app/invoices/ListView.vue"
                                    ),
                                meta: {
                                    title: "Satış Faturaları",
                                    page_title: "Satış Faturaları",
                                },
                            },
                            {
                                path: "yeni",
                                name: "CreateSaleInvoice",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "CreateSaleInvoice" */ "../views/app/invoices/CreateView.vue"
                                    ),
                                meta: {
                                    title: "Yeni Fatura",
                                    page_title: "Yeni Fatura",
                                },
                            },
                            {
                                path: ":id",
                                name: "SaleInvoice",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "SaleInvoice" */ "../views/app/invoices/ShowView.vue"
                                    ),
                                meta: { title: "Fatura", page_title: "Fatura" },
                            },
                            {
                                path: ":id/iade",
                                name: "SaleReturnInvoice",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "SaleReturnInvoice" */ "../views/app/invoices/CreateView.vue"
                                    ),
                                meta: {
                                    title: "İade Faturası",
                                    page_title: "İade Faturası",
                                },
                            },
                        ],
                    },
                    {
                        path: "alis-faturalari",
                        children: [
                            {
                                path: "",
                                name: "PurchaseInvoices",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "PurchaseInvoices" */ "../views/app/invoices/ListView.vue"
                                    ),
                                meta: {
                                    title: "Satınalma Faturaları",
                                    page_title: "Satınalma Faturaları",
                                },
                            },
                            {
                                path: "yeni",
                                name: "CreatePurchaseInvoice",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "CreatePurchaseInvoice" */ "../views/app/invoices/CreateView.vue"
                                    ),
                                meta: {
                                    title: "Yeni Fatura",
                                    page_title: "Yeni Fatura",
                                },
                            },
                            {
                                path: ":id",
                                name: "PurchaseInvoice",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "PurchaseInvoice" */ "../views/app/invoices/ShowView.vue"
                                    ),
                                meta: { title: "Fatura", page_title: "Fatura" },
                            },
                            {
                                path: ":id/iade",
                                name: "PurchaseReturnInvoice",
                                component: () =>
                                    import(
                                        /* webpackChunkName: "PurchaseReturnInvoice" */ "../views/app/invoices/CreateView.vue"
                                    ),
                                meta: {
                                    title: "İade Faturası",
                                    page_title: "İade Faturası",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "e-fatura-gelen-kutusu",
                children: [
                    {
                        path: "",
                        name: "InboxEInvoices",
                        component: () =>
                            import(
                                /* webpackChunkName: "InboxEInvoices" */ "../views/app/invoicesInbox/ListView.vue"
                            ),
                        meta: {
                            title: "Gelen Kutusu",
                            page_title: "Gelen Kutusu",
                        },
                    },
                    {
                        path: ":id",
                        name: "InboxEInvoice",
                        component: () =>
                            import(
                                /* webpackChunkName: "InboxEInvoice" */ "../views/app/invoicesInbox/CreateView.vue"
                            ),
                        meta: {
                            title: "Gelen Kutusu",
                            page_title: "Gelen Kutusu",
                        },
                    },
                ],
            },
            {
                path: "masraflar",
                children: [
                    {
                        path: "",
                        name: "Expenses",
                        component: () =>
                            import(
                                /* webpackChunkName: "Expenses" */ "../views/app/expenses/ListView.vue"
                            ),
                        meta: { title: "Masraflar", page_title: "Masraflar" },
                    },
                    {
                        path: "yeni",
                        name: "CreateExpense",
                        component: () =>
                            import(
                                /* webpackChunkName: "CreateExpense" */ "../views/app/expenses/CreateView.vue"
                            ),
                        meta: {
                            title: "Yeni Masraf",
                            page_title: "Yeni Masraf",
                        },
                    },
                    {
                        path: ":id",
                        name: "Expense",
                        component: () =>
                            import(
                                /* webpackChunkName: "Expense" */ "../views/app/expenses/ShowView.vue"
                            ),
                        meta: { title: "Masraf", page_title: "Masraf" },
                    },
                    {
                        path: ":id/guncelle",
                        name: "UpdateExpense",
                        component: () =>
                            import(
                                /* webpackChunkName: "UpdateExpense" */ "../views/app/expenses/CreateView.vue"
                            ),
                        meta: {
                            title: "Masraf Güncelle",
                            page_title: "Güncelle",
                        },
                    },
                ],
            },
            {
                path: "urun-ve-hizmetler",
                children: [
                    {
                        path: "",
                        name: "Products",
                        component: () =>
                            import(
                                /* webpackChunkName: "Products" */ "../views/app/products/ListView.vue"
                            ),
                        meta: {
                            title: "Ürün ve Hizmetler",
                            page_title: "Ürün ve Hizmetler",
                        },
                    },
                    {
                        path: "yeni",
                        name: "CreateProduct",
                        component: () =>
                            import(
                                /* webpackChunkName: "CreateProduct" */ "../views/app/products/CreateView.vue"
                            ),
                        meta: {
                            title: "Yeni Ürün ve Hizmet",
                            page_title: "Yeni Ürün/Hizmet",
                        },
                    },
                    {
                        path: ":id",
                        name: "Product",
                        component: () =>
                            import(
                                /* webpackChunkName: "Product" */ "../views/app/products/CreateView.vue"
                            ),
                        meta: {
                            title: "Ürün ve Hizmet",
                            page_title: "Ürün/Hizmet",
                        },
                    },
                ],
            },
            {
                path: "kasa-ve-bankalar",
                children: [
                    {
                        path: "",
                        name: "Accounts",
                        component: () =>
                            import(
                                /* webpackChunkName: "Accounts" */ "../views/app/accounts/ListView.vue"
                            ),
                        meta: {
                            title: "Kasa ve Bankalar",
                            page_title: "Kasa ve Bankalar",
                        },
                    },
                    {
                        path: "yeni",
                        name: "CreateAccount",
                        component: () =>
                            import(
                                /* webpackChunkName: "CreateAccount" */ "../views/app/accounts/CreateView.vue"
                            ),
                        meta: {
                            title: "Yeni Kasa Banka",
                            page_title: "Yeni Kasa/Banka",
                        },
                    },
                    {
                        path: ":id",
                        name: "Account",
                        component: () =>
                            import(
                                /* webpackChunkName: "Account" */ "../views/app/accounts/ShowView.vue"
                            ),
                        meta: {
                            title: "Kasa ve Banka",
                            page_title: "Kasa/Banka",
                        },
                    },
                    {
                        path: ":id/guncelle",
                        name: "UpdateAccount",
                        component: () =>
                            import(
                                /* webpackChunkName: "UpdateAccount" */ "../views/app/accounts/CreateView.vue"
                            ),
                        meta: {
                            title: "Hesap Güncelle",
                            page_title: "Güncelle",
                        },
                    },
                ],
            },
            {
                path: "raporlar",
                children: [
                    {
                        path: "kar-zarar-ozeti",
                        name: "ProfitAndLossSummary",
                        component: () =>
                            import(
                                /* webpackChunkName: "ProfitAndLossSummary" */ "../views/app/reports/ProfitAndLossView.vue"
                            ),
                        meta: {
                            title: "Kâr-Zarar Özeti",
                            page_title: "Kâr-Zarar Özeti",
                        },
                    },
                    {
                        path: "vergi-ozeti",
                        name: "TaxSummary",
                        component: () =>
                            import(
                                /* webpackChunkName: "TaxSummary" */ "../views/app/reports/TaxSummaryView.vue"
                            ),
                        meta: {
                            title: "Vergi Özeti",
                            page_title: "Vergi Özeti",
                        },
                    },
                    {
                        path: "gelir-ozeti",
                        name: "IncomingSummary",
                        component: () =>
                            import(
                                /* webpackChunkName: "IncomingSummary" */ "../views/app/reports/IncomingOutgoingSummary.vue"
                            ),
                        meta: {
                            title: "Gelir Özeti",
                            page_title: "Gelir Özeti",
                        },
                    },
                    {
                        path: "gider-ozeti",
                        name: "OutgoingSummary",
                        component: () =>
                            import(
                                /* webpackChunkName: "OutgoingSummary" */ "../views/app/reports/IncomingOutgoingSummary.vue"
                            ),
                        meta: {
                            title: "Gider Özeti",
                            page_title: "Gider Özeti",
                        },
                    },
                ],
            },
            {
                path: "ayarlar",
                children: [
                    {
                        path: "firma-ayarlari",
                        name: "CompanySettings",
                        component: () =>
                            import(
                                /* webpackChunkName: "Categories" */ "../views/app/settings/CompanySettingsView.vue"
                            ),
                        meta: {
                            title: "Firma Ayarları",
                            page_title: "Firma Ayarları",
                        },
                    },
                    {
                        path: "e-fatura-ayarlari",
                        name: "EInvoiceSettings",
                        component: () =>
                            import(
                                /* webpackChunkName: "EInvoiceSettings" */ "../views/app/settings/EInvoiceSettingsView.vue"
                            ),
                        meta: {
                            title: "E-Fatura Ayarları",
                            page_title: "E-Fatura Ayarları",
                        },
                    },
                    {
                        path: "kategoriler",
                        name: "Categories",
                        component: () =>
                            import(
                                /* webpackChunkName: "Categories" */ "../views/app/settings/CategoriesView.vue"
                            ),
                        meta: {
                            title: "Kategoriler",
                            page_title: "Kategoriler",
                        },
                    },
                ],
            },
            {
                path: "profilim",
                name: "Profile",
                component: () =>
                    import(
                        /* webpackChunkName: "Profile" */ "../views/app/profile/IndexView.vue"
                    ),
                meta: { title: "Profil", page_title: "Profil" },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
