<script setup>
const props = defineProps({
    toggleClose: {
        type: Boolean,
        default: false,
    },
});

const sidebarToggle = () => {
    document.body.classList.toggle("sidebar-reverse");
};
</script>

<template>
    <button
        type="button"
        class="btn sidebar-toggle-btn"
        :class="{ 'btn-close': toggleClose }"
        @click="sidebarToggle"
    >
        <slot v-if="!toggleClose">
            <i class="bi bi-filter-right fs-2 lh-1"></i>
        </slot>
    </button>
</template>

<style lang="scss" scoped>
.sidebar-toggle-btn {
    transition: 0.5s transform ease;
    -webkit-transition: 0.5s transform ease;
    -moz-transition: 0.5s transform ease;
    -ms-transition: 0.5s transform ease;
    -o-transition: 0.5s transform ease;

    // .sidebar-reverse & {
    //     transform: rotate(180deg);
    //     -webkit-transform: rotate(180deg);
    //     -moz-transform: rotate(180deg);
    //     -ms-transform: rotate(180deg);
    //     -o-transform: rotate(180deg);
    // }
}
</style>
