export default {
    install: (app) => {
        const store = app.config.globalProperties.$store;

        app.provide("fetchLoading", {
            show() {
                store.dispatch("setFetchLoading", true);
            },

            hide() {
                store.dispatch("setFetchLoading", false);
            },

            getStatus() {
                return store.getters["fetchLoading"];
            },
        });
    },
};
