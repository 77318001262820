import axios from "axios";
import Cookies from "js-cookie";
import router from "../../router";

const TOKEN_NAME = process.env.VUE_APP_STROGE_TOKEN_NAME;
const TOKEN_EXPIRE_NAME = process.env.VUE_APP_STROGE_TOKEN_EXPIRE_NAME;
const REMEMBER_COOKIE_NAME = process.env.VUE_APP_STROGE_REMEMBER_COOKIE_NAME;
const COMPANY_NAME = process.env.VUE_APP_STROGE_COMPANY_NAME;

// State
const state = {
    token: localStorage.getItem(TOKEN_NAME) || "",
    company: localStorage.getItem(COMPANY_NAME) || "",
};

// Getters
const getters = {
    isAuth: (state) => !!state.token,
    token: (state) => state.token,
    company: (state) => state.company,
};

// Mutations
const mutations = {
    setToken(state, data) {
        localStorage.setItem(TOKEN_NAME, data.token);
        localStorage.setItem(TOKEN_EXPIRE_NAME, data.expire_time);
        state.token = data.token;
    },

    setCompany(state, companyId) {
        localStorage.setItem(COMPANY_NAME, companyId);
        state.company = companyId;
    },

    clearAuth(state) {
        state.token = "";
        state.company = "";
    },
};

// Actions
const actions = {
    initAuth({ dispatch }) {
        return new Promise(async (resolve) => {
            const tokenExpire = localStorage.getItem(TOKEN_EXPIRE_NAME);
            if (!tokenExpire) {
                dispatch("timeout", 10);
                return resolve();
            }

            // Oturum süresi devam ediyorsa
            const currentTime = new Date().getTime();
            if (currentTime < tokenExpire) {
                const remainingTime = tokenExpire - (currentTime - 2000);
                dispatch("timeout", remainingTime);
                return resolve();
            } else {
                await dispatch("refreshLogin")
                    .catch(() => {
                        dispatch("logout");
                    })
                    .finally(() => {
                        resolve();
                    });
            }
        });
    },

    login({ commit, dispatch }, user) {
        return new Promise(async (resolve, reject) => {
            const { email, password, refresh_token, remember } = user;
            const data = {};
            if (refresh_token) {
                data.refresh_token = refresh_token;
            } else {
                data.email = email;
                data.password = password;
            }

            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_AUTH_URL}/login`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json;charset=utf-8",
                            "X-Requested-With": "XMLHttpRequest",
                            Accept: "application/json",
                        },
                    }
                );

                const {
                    token_type,
                    access_token,
                    access_token_expire,
                    refresh_token,
                    refresh_token_expire,
                    companies,
                } = res.data.data;

                const expireTime = new Date(access_token_expire).getTime();
                const refreshMs = expireTime - new Date().getTime();
                dispatch("timeout", refreshMs);

                commit("setToken", {
                    token: access_token,
                    expire_time: expireTime,
                });
                commit("setCompany", companies[0]);

                // Beni hatırla seçili ise
                if (remember) {
                    const refreshExpireMs =
                        new Date(refresh_token_expire).getTime() -
                        new Date().getTime();
                    const refreshExpireDay = refreshExpireMs / 86400;

                    Cookies.set(REMEMBER_COOKIE_NAME, refresh_token, {
                        expires: refreshExpireDay,
                        secure: true,
                        sameSite: "Lax",
                    });
                } else {
                    Cookies.remove(REMEMBER_COOKIE_NAME);
                }
                resolve(access_token);
            } catch (error) {
                reject(error);
            }
        });
    },

    refreshLogin({ dispatch }) {
        return new Promise((resolve, reject) => {
            const rememberCookie = Cookies.get(REMEMBER_COOKIE_NAME) || "";
            if (rememberCookie && rememberCookie != "undefined") {
                dispatch("login", {
                    refresh_token: rememberCookie,
                    remember: true,
                })
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } else {
                reject("not found");
            }
        });
    },

    timeout({ dispatch }, expirationMs) {
        setTimeout(() => {
            dispatch("refreshLogin").catch(() => {
                dispatch("logout");
            });
        }, expirationMs);
    },

    logout({ commit }) {
        commit("clearAuth");
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(TOKEN_EXPIRE_NAME);
        localStorage.removeItem(COMPANY_NAME);
        Cookies.remove(REMEMBER_COOKIE_NAME);
        router.push({ name: "Login" });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
