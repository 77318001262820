<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

const item = computed(() => {
    const mes = store.getters.message;

    if (mes) {
        setTimeout(() => {
            clear();
        }, 4000);
    }

    return mes;
});

const clear = () => {
    store.dispatch("clearMessage");
};

watch(route, () => {
    if (item.value) clear();
});
</script>

<template>
    <Transition name="fade" mode="out-in">
        <div v-if="item" class="toast-container">
            <div class="toast">
                <i class="bi-check2-circle"></i>
                {{ item }}
            </div>
        </div>
    </Transition>
</template>

<style lang="scss" scoped>
.toast-container {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 320px;
    margin-top: 1rem;
    z-index: 1091;

    .toast {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.51rem 1rem;
        color: var(--bs-success);
        background-color: rgba(var(--bs-success-rgb), 0.18);
        border-radius: 0.458rem;
        box-shadow: -2px 2px 10px 0px rgb(124 124 124 / 25%);

        i {
            font-size: 1.6rem;
            margin-right: 1rem;
        }
    }
}
</style>
