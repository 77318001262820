import axios from "@/plugins/axios";

// State
const state = {
    categories: [],
    countries: [],
    currencies: [],
    taxCodes: [],
    unitCodes: [],
};

// Getters
const getters = {
    categories: (state) => state.categories,
    countries: (state) => state.countries,
    currencies: (state) => state.currencies,
    taxCodes: (state) => state.taxCodes,
    unitCodes: (state) => state.unitCodes,
    invoiceProfiles:
        () =>
        (earchive = false) => {
            if (!earchive) return ["EARSIVFATURA"];

            return ["TEMELFATURA", "TICARIFATURA"];
        },
};

// Mutations
const mutations = {
    setCategories(state, data) {
        state.categories = data;
    },

    setCountries(state, data) {
        state.countries = data;
    },

    setCurrencies(state, data) {
        state.currencies = data;
    },

    setTaxCodes(state, data) {
        state.taxCodes = data;
    },

    setUnitCodes(state, data) {
        state.unitCodes = data;
    },
};

// Actions
const actions = {
    getCategories({ commit }) {
        return new Promise(async (resolve, reject) => {
            await axios
                .get("categories")
                .then((res) => {
                    const data = res.data.data;
                    commit("setCategories", data);
                    setTimeout(() => {
                        resolve();
                    }, 3000);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getCountries({ commit }) {
        return new Promise(async (resolve, reject) => {
            await axios
                .get("globals/countries")
                .then((res) => {
                    const data = res.data.data;
                    commit("setCountries", data);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getCurrencies({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("globals/currencies")
                .then((res) => {
                    const data = res.data.data;
                    commit("setCurrencies", data);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getTaxCodes({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("globals/tax-codes")
                .then((res) => {
                    const data = res.data.data;
                    commit("setTaxCodes", data);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getUnitCodes({ commit }) {
        return new Promise(async (resolve, reject) => {
            await axios
                .get("globals/unit-codes")
                .then((res) => {
                    const data = res.data.data;
                    commit("setUnitCodes", data);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
