import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Plugins
import axios from "./plugins/axios";
import helper from "./plugins/helper";
import fetchLoading from "./plugins/fetchLoading";

// Directives
import LoadingDirective from "./directives/loading";

/* Bootstrap */
import "./assets/scss/style.scss";
import "bootstrap-icons/font/bootstrap-icons.css";

// Global Components
import Modal from "./components/global/Modal.vue";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(helper);
app.use(fetchLoading);

app.provide("axios", axios);

// v-loading
app.directive("loading", LoadingDirective);

app.component("Modal", Modal);

app.mount("#app");
