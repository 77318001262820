<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const email = ref(null);
const password = ref(null);
const remember = ref(false);

const loading = ref(false);
const LoginForm = ref(null);

const save = async () => {
    if (!LoginForm.value.checkValidity()) {
        LoginForm.value.classList.add("was-validated");
        return;
    }

    const data = {
        email: email.value,
        password: password.value,
        remember: remember.value,
    };

    loading.value = true;
    try {
        const login = await store.dispatch("auth/login", data);
        router.replace({ name: "Dashboard" });
    } catch (err) {
        store.dispatch("setAlerts", err.response.data.messages);
    } finally {
        loading.value = false;
    }
};
</script>

<template>
    <form @submit.prevent ref="LoginForm" novalidate v-loading="loading">
        <div class="mb-3">
            <label for="login-email" class="form-label"> E-posta </label>
            <input
                v-model="email"
                type="email"
                class="form-control"
                id="login-email"
                placeholder="eposta@adresi.com"
                required
            />
        </div>
        <div class="mb-3 position-relative">
            <label for="login-password" class="form-label"> Şifre </label>
            <input
                v-model="password"
                type="password"
                class="form-control"
                id="login-password"
                placeholder="············"
                required
                minlength="6"
                maxlength="12"
                @keydown.enter="save"
            />
            <a href="#" class="small position-absolute top-0 end-0">
                Şifremi unuttum!
            </a>
        </div>
        <div class="form-check mb-3">
            <input
                v-model="remember"
                type="checkbox"
                class="form-check-input"
                id="remember"
            />
            <label for="remember" class="form-check-label">
                Beni hatırla
            </label>
        </div>
        <button type="button" class="btn btn-primary w-100" @click="save">
            Giriş yap
        </button>
    </form>
</template>
