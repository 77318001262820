import axios from "axios";
import store from "@/store";

const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}`,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
    },
});

// Add a request interceptor
instance.interceptors.request.use(
    async (config) => {
        const token = store.getters["auth/token"];
        const company = store.getters["auth/company"];

        config.baseURL = config.baseURL + "/" + company;
        if (token) config.headers.Authorization = `Bearer ${token}`;

        // Do something before request is sent
        return config;
    },

    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
instance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },

    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        return Promise.reject(error);
    }
);

export default instance;
