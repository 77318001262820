export default {
    mounted(el, binding) {
        if (binding.value === true) el.classList.add("waiting-component");
        else el.classList.remove("waiting-component");
    },

    updated(el, binding) {
        if (binding.value === true) el.classList.add("waiting-component");
        else el.classList.remove("waiting-component");
    },
};
