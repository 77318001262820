<script setup>
import { ref } from "vue";
const version = ref(process.env.VUE_APP_VERSION);
</script>

<template>
    <div class="main-footer d-flex align-items-center">
        <div class="container-fluid clearfix">
            <span
                class="text-muted d-block text-center text-sm-start d-sm-inline-block"
            >
                Copyright &copy; {{ new Date().getFullYear() }}
                <span class="text-primary">Bi Hesapp</span> All rights reserved
            </span>

            <span
                class="float-none float-sm-end d-block mt-1 mt-sm-0 text-center small text-muted"
            >
                Version {{ version }}
            </span>
        </div>
    </div>
</template>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.main-footer {
    font-size: 80%;
    height: $footer-height;
}

@media screen and (max-width: 991px) {
    .main-footer {
        font-size: 0.7rem;
        padding: 0 $content-padding-sm-x;
    }
}
</style>
