<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

const alerts = computed(() => {
    return store.getters["alerts"];
});

const clearAlerts = () => {
    store.dispatch("clearAlerts");
};

watch(route, () => {
    if (alerts.value.length) clearAlerts();
});
</script>

<template>
    <Transition name="fade" mode="out-in">
        <div
            v-if="alerts.length"
            class="alert-container d-flex align-items-center bg-light-danger"
            role="alert"
        >
            <ul class="m-0 flex-grow-1">
                <li v-for="(alert, index) in alerts" :key="index">
                    {{ alert }}
                </li>
            </ul>

            <button
                type="button"
                class="alert-close"
                aria-label="Close"
                @click="clearAlerts"
            >
                x
            </button>
        </div>
    </Transition>
</template>

<style lang="scss" scoped>
.alert-container {
    position: relative;
    margin-bottom: 1rem;
    padding: 0.71rem 1rem;
    border-radius: 0.358rem;

    .alert-close {
        color: inherit;
        background: transparent;
        border: none;
    }
}
</style>
