import { createStore } from "vuex";

// Modules
import auth from "./modules/auth";
import global from "./modules/global";

export default createStore({
    state: {
        fetchLoading: false,
        alerts: [],
        message: null,
    },
    getters: {
        fetchLoading: (state) => state.fetchLoading,
        alerts: (state) => state.alerts,
        message: (state) => state.message,
    },
    mutations: {
        fetchLoading(state, value) {
            state.fetchLoading = value;
        },

        alerts(state, value) {
            state.alerts = value;
        },

        message(state, value) {
            state.message = value;
        },
    },
    actions: {
        setFetchLoading({ commit }, value) {
            commit("fetchLoading", value);
        },

        setAlerts({ commit }, value) {
            let messages = [];

            if (typeof value === "object") {
                Object.keys(value).forEach((key) => {
                    messages.push(value[key]);
                });
            } else {
                messages.push(value);
            }
            commit("alerts", messages);
            window.scrollTo({
                top: 0,
            });
        },

        clearAlerts({ commit }) {
            commit("alerts", []);
        },

        setMessage({ commit }, value) {
            commit("message", value);
            window.scrollTo({
                top: 0,
            });
        },

        clearMessage({ commit }) {
            commit("message", null);
        },
    },
    modules: {
        auth,
        global,
    },
});
