export default {
    install: (app) => {
        app.config.globalProperties.$helper = {
            phoneClear(value) {
                let newPhone = value.replace(/^(9|\+9|90|\+90|0)|[^0-9]/g, "");

                if (newPhone[0] != 0 && newPhone.length > 10)
                    newPhone = newPhone.substr(0, 10);

                return newPhone;
            },

            dateView(value) {
                if (!value) return "-";

                const [date, time] = value.split(" ");
                const [year, month, day] = date.split("-");

                return new Date(year, month - 1, day).toLocaleDateString(
                    "tr-TR",
                    {
                        year: "numeric",
                        // month: "long",
                        month: "short",
                        day: "numeric",
                    }
                );
            },

            dateTimeView(value) {
                if (!value) return "-";

                const [date, time] = value.split(" ");
                const [year, month, day] = date.split("-");
                const [hour, minute, second] = time.split(":");
                return new Date(
                    year,
                    month - 1,
                    day,
                    hour,
                    minute,
                    second
                ).toLocaleString("tr-TR");
            },

            dateValue(value) {
                if (!value) return null;

                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();

                return `${year}-${month}-${day}`;
            },

            dateTimeValue(value) {
                if (!value) return null;

                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                const hour = String(date.getHours()).padStart(2, "0");
                const minute = String(date.getMinutes()).padStart(2, "0");
                const second = String(date.getSeconds()).padStart(2, "0");

                return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            },

            getDueDay(value) {
                if (!value) return;

                const today = new Date();
                const dueDate = new Date(value);
                const diffTime = dueDate - today;
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                let response;
                if (diffDays > 0) response = diffDays + " Gün Sonra";
                else if (diffDays < 0) response = diffDays + " Gün Gecikti";
                else response = "Bugün";

                return response;
            },

            priceView(value) {
                const formattedOutput = new Intl.NumberFormat("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });

                return value
                    ? formattedOutput.format(value).replace("₺", "")
                    : "0,00";
            },

            replaceDot(value) {
                if (!value) return null;
                if (!String(value).includes(",")) return value;

                return value.replace(",", ".");
            },

            listRowNumber(index, pageNumber, perPage) {
                if (!pageNumber || pageNumber == 1) return index + 1;

                if (pageNumber > 1)
                    return pageNumber * perPage - perPage + (index + 1);
            },

            snakeToCamelCase(str) {
                return snakeToCamelCaseFn(str);
            },
            snakeToPascalCase(str) {
                return (
                    str.charAt(0).toUpperCase() +
                    snakeToCamelCaseFn(str).slice(1)
                );
            },

            transactionName(str) {
                const transactions = {
                    sale_invoice: "SATIŞ FATURASI",
                    purchase_invoice: "SATINALMA FATURASI",
                    sale_return_invoice: "SATIŞ İADE FATURASI",
                    purchase_return_invoice: "SATINALMA İADE FATURASI",
                    expense: "MASRAF",
                    collection: "TAHSİLAT",
                    payment: "ÖDEME",
                    open_balance: "AÇILIŞ BAKİYESİ",
                    money_in: "PARA GİRİŞİ",
                    money_out: "PARA ÇIKIŞI",
                    money_transfer: "VİRMAN",
                };

                return transactions[str] ?? str;
            },
        };
    },
};

const snakeToCamelCaseFn = (str) => {
    return str
        .toLowerCase()
        .replace(/([-_][a-z])/g, (group) =>
            group.toUpperCase().replace("-", "").replace("_", "")
        );
};
