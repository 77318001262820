<script>
import AppSidebar from "@/components/appCommon/Sidebar.vue";
import AppNavbar from "@/components/appCommon/Navbar.vue";
import AppFooter from "@/components/appCommon/Footer.vue";
import Toast from "@/components/global/Toast.vue";
import Alerts from "@/components/global/Alerts.vue";
import store from "../../store";

export default {
    components: { AppSidebar, AppNavbar, AppFooter, Toast, Alerts },

    computed: {
        fetchLoading() {
            return store.getters["fetchLoading"];
        },
    },

    async mounted() {
        await store.dispatch("global/getCategories");
        await store.dispatch("global/getCountries");
        await store.dispatch("global/getCurrencies");
        await store.dispatch("global/getTaxCodes");
        await store.dispatch("global/getUnitCodes");
    },
};
</script>

<template>
    <AppSidebar />
    <div class="content-wrapper">
        <AppNavbar />
        <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in" appear>
                <div class="content container-fluid" :key="route">
                    <Toast />
                    <Alerts />
                    <div class="fetch-loading" :class="{ show: fetchLoading }">
                        <p>Lütfen bekleyin..</p>
                        <div class="animate-line"></div>
                    </div>
                    <h1 v-if="$route.meta.page_title" class="page-title">
                        {{ $route.meta.page_title }}
                    </h1>
                    <component :is="Component" />
                </div>
            </transition>
        </router-view>
        <AppFooter />
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.content-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    padding-left: $sidebar-width;
    width: 100%;
    min-height: 100vh;
    transition: 0.5s ease-in-out;

    > .content {
        position: relative;
        padding: $content-padding-y $content-padding-x;
        width: 100%;
        min-height: calc(100vh - #{$navbar-height} - #{$footer-height});

        .page-title {
            display: block;
            font-size: $h1-font-size;
            font-weight: lighter;
            width: 100%;
            margin-bottom: 3rem;
        }
    }

    .sidebar-reverse & {
        padding-left: 0;
    }
}

@media screen and (max-width: 991px) {
    #app {
        .content-wrapper {
            width: 100%;
            padding-left: 0;

            > .content {
                position: relative;
                padding: $content-padding-sm-y $content-padding-sm-x;
                width: 100%;
                min-height: calc(100vh - #{$navbar-height} - #{$footer-height});

                .page-title {
                    font-size: $h2-font-size;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
</style>
